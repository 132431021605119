import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import DrBanitaMann from '../images/DrBanitaMann-Resize2.jpeg';
import DrSmitaKumar from '../images/DrSmitaKumar-Resize.jpg';
import DrJasdeepKhela from '../images/DrJasdeepKhela-Resize.jpg';
import AppointmentCTA from '../components/AppointmentCTA';
import SEO from '../components/Seo';

const Team = () => {

    return(
        <Layout>
            <SEO title="Muse Dental - Our Team"/>
            <header>
            <div className="relative bg-muse-yellow">
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl text-center">Our Team</h1>
                </div>
                </div>
            </header>

            <section>
                <div className="bg-white">
                    <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                    <div className="space-y-12 lg:grid lg:grid-cols-4 lg:gap-8 lg:space-y-0">
                        <div className="lg:col-span-4">
                        <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
                            <li className="sm:py-8">
                            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                                <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                <img className="object-cover shadow-lg rounded-lg" src={DrBanitaMann} alt="Dr. Banita Mann"/>
                                </div>
                                <div className="sm:col-span-2">
                                <div className="space-y-4">
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                    <h3 className="text-2xl font-bold text-muse-teal">Dr. Banita Mann</h3>
                                    <p className="text-xl text-gray-700 font-bold">General Dentist</p>
                                    </div>
                                    <div className="text-lg">
                                    <p className="text-gray-500">
                                    Dr. Mann's journey began with humble roots in Delta, BC, where she was raised by two hardworking immigrant parents who instilled in her the value of perseverance and education. Driven by these values, she graduated with honors from the University of Minnesota's dental school while her husband completed his Family Medicine residency. The couple then returned home to start their family and launch their respective practices.
                                    </p>
                                    <p className="mt-5 text-gray-500"> 
                                    Beyond her role as a dedicated dentist and practice owner, Dr. Mann is a full-time mom to her two children, Noah and Ella. Since childhood, she has aspired to make a lasting impact and give back to her community, understanding that oral health is a crucial part of overall well-being. 
                                    </p>
                                    <p className="mt-5 text-gray-500">
                                    Good oral health is essential not just for basic functions like speaking, smiling, smelling, and eating, but also for effective communication, building human relationships, and achieving financial success. Poor dental health can lead to severe consequences, including painful, disabling, and costly health issues.
                                    </p>
                                    <p className="mt-5 text-gray-500">
                                    Committed to providing the highest quality care, Dr. Mann regularly attends Continuing Education seminars and courses to stay at the forefront of dental healthcare. Book your new patient appointment today and experience the exceptional care Dr. Mann offers!
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </li>

                          {/*  <li className="sm:py-8">
                            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    
                                <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                <img className="object-cover shadow-lg rounded-lg" src={DrSmitaKumar} alt="Dr. Smita Kumar"/>
                                </div>
                                <div className="sm:col-span-2">
                                <div className="space-y-4">
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                    <h3 className="text-2xl font-bold text-muse-teal">Dr. Smita Kumar</h3>
                                    <p className="text-xl text-gray-700 font-bold">General Dentist</p>
                                    </div>
                                    <div className="text-lg">
                                    <p className="text-gray-500">
                                    Dr. Smita Kumar recently moved to Vancouver from Chicago, USA. She has more than 6 years of experience providing comprehensive dental care to patients of all ages. 
                                    </p>
                                    <p className="mt-5 text-gray-500">
                                    Since graduating from University of Minnesota, 
                                    Dr. Kumar has attended hundreds of hours of Continuing Education courses to provide her patients with the most up to date dental treatments. In her spare time, she enjoys running, reading and cooking. 
                                    She is excited to explore British Columbia with her husband.
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>
    </li> */}

                            {/* <li className="sm:py-8">
                            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    
                                <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                <img className="object-cover shadow-lg rounded-lg" src={DrJasdeepKhela} alt="Dr. Jasdeep Khela"/>
                                </div>
                                <div className="sm:col-span-2">
                                <div className="space-y-4">
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                    <h3 className="text-2xl font-bold text-muse-teal">Dr. Jasdeep Khela</h3>
                                    <p className="text-xl text-gray-700 font-bold">General Dentist</p>
                                    </div>
                                    <div className="text-lg">
                                   
                                    <p className="text-gray-500">
                                    Dr. Jasdeep Khela earned her Doctor of Dental Medicine from the University of British Columbia in Vancouver, BC. Her most recent position involved providing dental care to underserved communities in Northern BC. 
                                    In her practice, Dr. Khela empowers patients to make informed decisions towards their oral health. She is known for her attention to detail and chair-side manner.
                                    Outside of dentistry, Dr. Khela enjoys hikes, reading, and movie theatre popcorn.
                                    </p>
                                    <p className="mt-5 text-gray-500">
                                    Joining the Muse Dental family in Summer of 2021, Dr. Khela looks forward to building quality connections with her patients, colleagues, and community.
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </li> */}

                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

        <AppointmentCTA/>
        </Layout>
    )
}

export default Team;